<template>
  <div class="main_container">
    <div class="task_detail_container" v-loading="loading">

      <!-- 详情页头部 -->
      <detail-title
        :backToPath="'/company/manage'"
        :descriptionList="descriptionList"
      ></detail-title>

      <!-- 详情信息列表 -->
      <task-description
        :isMaterials="true"
        :descriptionList="descriptionList">
      </task-description>

      <!-- 按钮组 -->
      <div class="task_submit">
        <el-button
          v-if="descriptionList.status === TASK_STATUS.WAITING_FOR_COMPANY"
          size="small"
          type="primary"
          @click="completeVisible = true">
          确认完成
        </el-button>
        <el-button
          v-if="descriptionList.status === TASK_STATUS.ONGOING || descriptionList.status === TASK_STATUS.PUBLISHED"
          size="small"
          type="warning"
          @click="cancelVisible = true"
          plain>
          取消任务
        </el-button>
        <el-button
          v-if="descriptionList.status === TASK_STATUS.WAITING_FOR_COMPANY"
          size="small"
          type="danger"
          @click="repulseVisible = true"
          plain>
          打回重做
        </el-button>
      </div>
    </div>

    <!-- 任务相关信息 -->
    <task-related-info></task-related-info>

    <!-- 取消任务弹窗 -->
    <cancel-task-dialog
      :visible.sync="cancelVisible">
    </cancel-task-dialog>

    <!-- 完成任务弹窗 -->
    <complete-task-dialog
      :visible.sync="completeVisible">
    </complete-task-dialog>

    <!-- 打回任务弹窗 -->
    <repulse-task-dialog
      :visible.sync="repulseVisible"
      :descriptionList="descriptionList">
    </repulse-task-dialog>
  </div>
</template>

<script>
import api from '@/api'
import globalVar from '@/configs/globalVar'
import TaskRelatedInfo from '@/components/task-detail/related-info/index.vue'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'
import CancelTaskDialog from '../components/cancel-task-dialog.vue'
import CompleteTaskDialog from '../components/complete-task-dialog.vue'
import RepulseTaskDialog from '../components/repulse-task-dialog.vue'

export default {
  components: {
    TaskRelatedInfo,
    DetailTitle,
    TaskDescription,
    CancelTaskDialog,
    CompleteTaskDialog,
    RepulseTaskDialog
  },
  data () {
    return {
      completeVisible: false,
      cancelVisible: false,
      repulseVisible: false,
      descriptionList: {},
      loading: false
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    },
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    handleCompleteTask () {
      this.completeVisible = false
    },
    handleCancelTask () {
      this.cancelVisible = false
    },
    handleRepulseTask () {
      this.repulseVisible = false
    },
    // 获取任务状态
    getTaskDetail () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data
        } else {
          this.$message.error('未获取到任务状态')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main_container {
  width: 100%;
  height: 100%;
  display: flex;
}
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #E4E7ED;
    display: flex;
    align-items: center;
  }
}
</style>
