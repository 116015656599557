<template>
  <el-dialog
    title="取消任务"
    :visible.sync="$_visible"
    width="500px">
    <span>如果无人接受任务，可直接取消！如有人接受任务，取消后将扣除5%的任务奖励作为补偿给任务接受人！</span>
    <span slot="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="handleCancelTask">
        确认
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    handleCancelTask () {
      api.cancelTask(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('任务已取消')
          this.$_visible = false
          this.$router.push('/company/manage')
        } else {
          this.$message.error('取消任务失败')
        }
      }).catch((error) => {
        this.btnLoading = false
        console.log(error)
        this.$message.error('请求异常')
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
