<template>
  <el-dialog
    title="打回重做"
    :visible.sync="$_visible"
    width="500px">
    <span>打回后将通知接受任务人，同一个任务接受人最多打回三次！</span>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="handleRepulseTask">
        确认
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    descriptionList: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      count: 0
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    handleRepulseTask () {
      if (this.descriptionList.againCount === 3) {
        this.$message.error('该任务打回超过三次，无法打回')
        return
      }
      api.repulseTask(this.descriptionList.taskingId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.closeDialog()
          this.$router.push('/company/manage')
        } else {
          this.$message.error('操作失败')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
