<template>
  <div class="information_container">
    <div class="title tw-font-bold">{{titleName}}</div>
    <div class="main_container">
      <div class="infomation_item" v-for="item in tableList" :key="item.id">
        <div :class="avatarStyle(item.role)">{{avatar(item)}}</div>
        <div class="name" :title="item.subjectName">{{item.subjectName}}</div>
        <div class="time">{{item.updatedAt}}</div>
        <div class="info" :title="item.actionName">{{item.actionName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    titleName: {
      type: String,
      default: '任务相关信息'
    }
  },
  data () {
    return {
      tableList: [],
      isLoading: false
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getInfoDetail()
  },
  methods: {
    // 详情任务中的行为消息
    getInfoDetail () {
      this.isLoading = true
      api.taskLogList(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.tableList = res.data.data.list
        } else {
          this.$message.error('任务相关信息获取失败')
        }
      }).catch(() => {
        this.$message.error('请求异常')
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 头像显示
    avatar (item) {
      let avatarType
      if (item.role === 0 || item.role === 1) {
        avatarType = String(item.subjectName).charAt(0)
      } else {
        avatarType = '管'
      }
      return avatarType
    },
    // 头像样式
    avatarStyle (role) {
      if (role === 0) return 'user'
      if (role === 1) return 'company'
      if (role === 2) return 'admin'
    }
  }
}
</script>

<style lang="scss" scoped>
.information_container {
  min-width: 420px;
  height: 380px;
  margin-left: 20px;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  overflow: auto;
  .title {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }
  .main_container {
    height: 300px;
    overflow: auto;
    .infomation_item {
      height: 50px;
      display: flex;
      align-items: center;
      .company {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        color: white;
        background: #5DD5C8;
      }
      .user {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        color: white;
        background: #3473E6;
      }
      .admin {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        color: white;
        background: #1B335D;
      }
      .name {
        width: 90px;
        height: 20px;
        margin-left: 10px;
        margin-right: 31px;
        font-size: 14px;
        font-weight: bold;
        color: #606266;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }
      .time {
        width: 124px;
        font-size: 12px;
        font-weight: 500;
        color: #909399;
        margin-right: 10px;
      }
      .info {
        width: 56px;
        font-size: 12px;
        font-weight: 500;
        color: #909399;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }
    }
  }
}
</style>
