<template>
  <el-dialog
    title="确认完成"
    :visible.sync="$_visible"
    width="500px">
    <el-form
      label-position="left"
      ref="completeForm"
      :model="form"
      :rules="rule"
      label-width="110px"
      hide-required-asterisk>
      <el-form-item
        label="完成程度"
        prop="completeness">
        <el-select
          size="small"
          style="width: 320px;"
          v-model.trim="form.completeness"
          placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="理由说明"
        prop="completionReason">
        <el-input
          type="textarea"
          style="width: 320px;"
          maxlength="100"
          :rows="5"
          v-model.trim="form.completionReason">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleCompleteTask"
        :loading="btnLoading">
        确认
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [{
        value: 100,
        label: '100%（全额发放奖励）'
      }, {
        value: 80,
        label: '80%（发放80%奖励）'
      }, {
        value: 50,
        label: '50%（发放50%奖励）'
      }, {
        value: 20,
        label: '20%（全额发放奖励）'
      }, {
        value: 0,
        label: '0（不发放奖励）'
      }],
      form: {
        completeness: '',
        completionReason: ''
      },
      btnLoading: false,
      rule: {
        completeness: [
          { required: true, message: '请选择任务完成度', trigger: 'blur' }
        ],
        completionReason: [
          { required: true, message: '请说明理由填写', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    handleCompleteTask () {
      this.$refs.completeForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.AcceptanceTask(this.taskId, { ...this.form }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('任务已完成')
              this.$_visible = false
              this.$router.push('/company/manage')
            } else {
              this.$message.error('任务结算未通过')
            }
          }).catch((error) => {
            console.log(error)
            this.$message.error('请求出错')
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
